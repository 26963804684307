import useAuthStore from '~/store/auth';

export default defineNuxtRouteMiddleware(() => {
  const authStore = useAuthStore();

  if (authStore.user) {
    const isAdmin = authStore.user.isAdmin;
    if (!isAdmin) {
      return navigateTo('/');
    }
  } else {
    authStore.setShowLoginModal(true);

    return navigateTo('/');
  }
});
